@import "custom";
@import "~bootstrap/scss/bootstrap";
@import "functions";
html {
  font-size: 15px;
  letter-spacing: 0.05em;
}
body {
  &.modal-mode {
    overflow: hidden;
  }
}
img {
  max-width: 100%;
  height: auto;
}
[v-cloak] {
  display: none;
}
@import "components/hero2";
@import "components/section";
@import "components/nayami";
@import "components/btn";
@import "components/nav";
@import "components/comparisonTable";
@import "components/profile";
@import "components/solutionArea";
@import "components/ctaArea";
@import "components/planArea";
@import "components/sectionHeader";
@import "components/gridLineup";
@import "components/planCard";
@import "components/modalDetail";
@import "components/digest";
@import "components/outline";
@import "components/marker";
@import "components/checkList";
@import "components/footer";
@import "components/animation";
