.checkList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: flex;
    gap: $spacer * 0.5;
    justify-content: flex-start;
    align-items: flex-start;
    &::before {
      font-family: "Font Awesome 5 Pro";
      content: "\f00c";
    }
  }
}
