#footer {
  padding: 30px 6vw;
  border-top: 1px solid $border-color;
  text-align: center;
  .gnav {
    margin-bottom: 15px;
    font-size: 12px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5em;
      li {
        a {
          color: $secondary;
          text-decoration: underline;
        }
      }
    }
  }
  .copyright {
    margin: 0;
    font-size: 10px;
    color: $secondary;
  }
}
