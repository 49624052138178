.nav.nav-pills {
  .nav-item {
    .nav-link {
      border-bottom: 2px solid transparent;
      color: inherit;
      opacity: 0.7;
      min-width: 100px;
      &.active {
        border-bottom: 2px solid;
        opacity: 1;
      }
    }
  }
}
