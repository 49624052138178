.section {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
}
.section-vp {
  width: 100vw;
  min-height: max(100vh, calc(9 / 16 * 100vw));
}
.bg-kumo {
  background-image: url(../img/bg-hero.webp);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: top left;
  @include media-breakpoint-up(sm) {
    background-size: 60%;
  }
}
