.digest {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: $spacer * 4;
  @include media-breakpoint-up(sm) {
    gap: $spacer * 5;
  }
  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $spacer * 2;
    position: relative;
    width: 100%;
    .cont {
      width: 100%;
      position: relative;
      z-index: 2;
      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: $spacer * 1.5;
        padding: 0 $spacer * 1;
        max-width: 550px;
        margin: 0 auto;
      }
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 100%;
        flex: 1;
      }
      .title {
        font-size: pxVwXS(21);
        font-weight: bold;
        letter-spacing: 0.1em;
        line-height: 2;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: pxVwSM(21);
          letter-spacing: 0.2em;
        }
        @include media-breakpoint-up(lg) {
          font-size: 24px;
        }
      }
      .text {
        font-size: pxVwXS(15);
        letter-spacing: 0.1em;
        line-height: 2;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
          letter-spacing: 0.2em;
        }
      }
    }
    .img {
      width: 100%;
      position: relative;
      z-index: 2;
      @include media-breakpoint-up(sm) {
        width: auto;
        flex: 1;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
      img {
      }
    }
    .illust {
      position: absolute;
      z-index: 3;
    }
    &:nth-child(odd) {
      .img {
        padding-right: $spacer * 1;
        @include media-breakpoint-up(sm) {
          padding: 0;
        }
      }
    }
    &:nth-child(even) {
      @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
      }
      .img {
        padding-left: $spacer * 1;
        @include media-breakpoint-up(sm) {
          padding: 0;
        }
      }
    }
  }
  & + .space {
    width: 100%;
    height: 90px;
    @include media-breakpoint-up(sm) {
      height: 120px;
    }
    @include media-breakpoint-up(lg) {
      height: 180px;
    }
  }
}
