.nayami {
  background-image: url(../img/wave-01.webp),
    linear-gradient(to bottom, white 50%, #f7f1eb 50%);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top left;
  text-align: center;
  padding-bottom: $spacer * 1;
  .header {
    margin-bottom: $spacer * 2.5;
    @include media-breakpoint-up(lg) {
      margin-bottom: $spacer * 3;
    }
    .icon {
      margin-bottom: $spacer * 1;
      img {
        height: 60px;
        width: auto;
      }
    }
    .title {
      font-size: pxVw(24);
      font-weight: bold;
      margin: 0;
      display: inline-block;
      padding-bottom: $spacer * 0.5;
      border-bottom: 2px solid;
      @include media-breakpoint-up(sm) {
        font-size: 30px;
        letter-spacing: 0.3em;
      }
    }
  }
  .body {
  }
}
