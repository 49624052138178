.planCard {
  width: 600px;
  margin: 0 auto;
  border: solid 5px $primary;
  background-color: $white;
  position: relative;
  max-width: 100%;
  &::before {
    content: url(../img/flag.webp);
    top: 0;
    right: $spacer * 1.5;
    position: absolute;
  }
  .header {
    padding: $spacer * 2.5 $spacer * 1.5 $spacer * 1.5;
    text-align: center;
    .title {
      margin-bottom: $spacer * 0.75;
      font-size: pxVwXS(27);
      font-weight: 700;
      line-height: 1.5;
      @include media-breakpoint-up(sm) {
        font-size: 30px;
      }
    }
    .price {
      font-size: pxVwXS(18);
      font-weight: 700;
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
      strong {
        font-size: 200%;
      }
    }
  }
  .body {
    padding: $spacer * 2 $spacer * 1.5;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: $spacer * 1.5;
      width: calc(100% - #{$spacer * 3});
      border-top: 1px dashed $body-color;
    }
    .checkList {
      font-size: 14px;
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer * 0.5 $spacer * 1;
        li {
          flex-basis: calc(50% - #{$spacer * 1});
        }
      }
    }
  }
}
