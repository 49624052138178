.planArea {
  position: relative;
  background-color: #f7f1eb;
  padding: 90px 0 60px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    border-top: 30px solid #fff;
    border-left: 50vw solid #fff;
    border-right: 50vw solid transparent;
    border-bottom: 30px solid transparent;
  }
  @include media-breakpoint-up(sm) {
    padding: 120px 0 90px;
    &::after {
      border-top: 40px solid #fff;
      border-bottom: 40px solid transparent;
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 150px 0 90px;
    &::after {
      border-top: 50px solid #fff;
      border-bottom: 50px solid transparent;
    }
  }
}
