.gridLineup {
  max-width: calc(990px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0 15px;
  .item {
    flex-basis: 100%; //スマホで1カラム
    @include media-breakpoint-up(sm) {
      flex-basis: calc((100% - 15px) / 2); //タブレットで2カラム
    }
    @include media-breakpoint-up(lg) {
      flex-basis: calc((100% - 30px) / 3); //PCで3カラム
    }
    a {
      width: 100%;
      max-width: 310px;
      margin: 0 auto;
      display: block;
      color: $body-color;
      text-decoration: none;
      transition: $transition-base;
      .thumbnail {
        position: relative;
        width: 100%;
        overflow: hidden;
        &::before {
          content: "";
          width: 100%;
          padding-top: calc(500 / 800 * 100%); //高さの比率 ÷ 幅の比率 × 100
          display: block;
        }
        img {
          transition: $transition-base;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .cont {
        padding: 12px 0 30px;
        .title {
          font-size: 15px;
          line-height: 1.25;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .more {
          font-size: 12px;
          margin-bottom: 0;
          display: inline-flex;
          gap: 3px;
          align-items: center;
          &::before {
            font-family: "Font Awesome 5 Pro";
            content: "\f105";
          }
        }
      }
      &:hover {
        color: $primary;
        .thumbnail {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
