.hero2 {
  position: relative;
  width: 100vw;
  height: 175vw;
  background-image: url(../img/bg-hero.webp);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: top left;
  @include media-breakpoint-up(sm) {
    height: calc(800 / 1280 * 100vw);
    background-size: 60%;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
  }
  .cont {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: $spacer * 2.5;
    padding: 6vw;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      gap: $spacer * 2;
      padding: 90px 6vw;
    }
    .text {
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      .catchCopy {
        margin: 0;
        font-size: 100%;
        margin-bottom: $spacer * 1.5;
        @include media-breakpoint-up(lg) {
          margin-bottom: $spacer * 1.75;
        }
      }
      .leadCopy {
        margin: 0;
        font-size: 15px;
        line-height: 1.75;
        margin-bottom: $spacer * 2;
        @include media-breakpoint-up(lg) {
          font-size: 18px;
          margin-bottom: $spacer * 3;
          letter-spacing: 0.15em;
        }
        br {
          display: none;
          @include media-breakpoint-up(sm) {
            display: inline;
          }
        }
      }
    }
    .img {
      width: 100%;
      @include media-breakpoint-up(sm) {
        flex: 1;
      }
    }
  }
}
