.outline {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: $spacer * 1.25;
  .img {
  }
  .cont {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: $spacer * 0.5;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      line-height: 1.5;
    }
    .text {
      font-size: 14px;
      margin: 0;
      line-height: 1.75;
    }
  }
}
