.comparisonTable {
  width: 750px;
  margin: 0 auto;
  tr {
    td {
      width: 200px;
      height: 90px;
      vertical-align: middle;
      text-align: center;
      border-bottom: 1px solid $body-color;
      border-right: 1px dashed $body-color;
      white-space: nowrap;
      &:first-child {
        border-right: 1px solid $body-color;
        width: 150px;
      }
      &:last-child {
        border-right: none;
      }
      &:nth-child(2) {
        background-color: $yellow-100;
        // background-color: $white;
        border-left: 3px solid $primary;
        border-right: 3px solid $primary;
        font-weight: bold;
      }
    }
    &:first-child {
      td {
        &:nth-child(2) {
          border-top: 3px solid $primary;
          background-color: $primary;
        }
      }
    }
    &:last-child {
      td {
        &:nth-child(2) {
          border-bottom: 3px solid $primary;
        }
      }
    }
  }
}
