.modalDetail {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba($color: #000000, $alpha: 0.9);
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  color: $white;
  .url {
    text-align: center;
    font-size: 12px;
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.modalClose {
  z-index: $zindex-fixed + 1;
  position: fixed;
  top: 0;
  right: 0;
  padding: 15px;
  cursor: pointer;
  opacity: 0.75;
  transition: $transition-base;
  color: $white;
  &:hover {
    opacity: 1;
  }
}
