.btn-cta {
  min-width: 250px;
  height: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(sm) {
    min-width: 300px;
    height: 80px;
    font-size: 18px;
  }
}
