.profile {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: $spacer * 1.5;
  .name {
    display: flex;
    flex-direction: column;
    gap: $spacer * 0.5;
    .title {
      font-size: 24px;
      margin: 0;
      line-height: 1.25;
    }
    .furigana {
      font-size: 12px;
      margin: 0;
      line-height: 1.25;
    }
  }
  .degree {
    font-size: 15px;
    margin: 0;
    font-weight: bold;
    letter-spacing: 0.2em;
  }
  .cont {
    p {
      margin: 0;
      line-height: 1.75;
    }
  }
}
