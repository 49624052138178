.solutionArea {
  background-color: $primary;
  background-image: url("../img/bg-solution-01.webp");
  padding: $spacer * 5 0;
  position: relative;
  .arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .illust {
    position: absolute;
    top: -20px;
    right: 5px;
    @include media-breakpoint-up(sm) {
      top: -75px;
      right: 15px;
    }
    img {
      max-width: 30vw;
      @include media-breakpoint-up(sm) {
        max-width: 25vw;
      }
    }
  }
  .title {
    text-align: center;
    margin-bottom: $spacer * 2;
  }
  .text {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.2em;
    margin-bottom: $spacer * 1;
  }
  .cta {
    text-align: center;
  }
}
