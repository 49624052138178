.sectionHeader {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: $spacer * 0.5;
  margin-bottom: $spacer * 3;
  .title-ja {
    line-height: 1.5;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacer * 0.25;
    &::before {
      content: "●";
      color: $primary;
    }
    &::after {
      content: "●";
      color: $primary;
    }
  }
  .title-en {
    line-height: 1;
    font-size: 45px;
    font-weight: 800;
    margin: 0;
  }
}
